<template>
    <!--begin::Aside-->
    <div class="flex-row-auto offcanvas-mobile w-250px w-xxl-350px" id="kt_profile_aside">
        <!--begin::Profile Card-->
        <div class="card card-custom card-stretch">
            <!--begin::Body-->
            <div class="card-body pt-4">
                <!--begin::User-->
                <div class="d-flex align-items-center">
                    <div
                        class="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center"
                    >
                        <div
                            v-if="
                                user.photoUrl !=
                                'https://sharedmailapi.pefoc.ro/img/default-avatar.png'
                            "
                            class="symbol-label"
                            :style="{ backgroundImage: `url(${user.photoUrl})` }"
                        ></div>
                        <div
                            class="symbol symbol-lg-75"
                            :class="[
                                user.status === 'active'
                                    ? 'symbol-light-success active'
                                    : '',
                                user.status === 'vacation'
                                    ? 'symbol-light-danger vacation'
                                    : '',
                                user.status === 'busy' ? 'symbol-light-warning busy' : '',
                            ]"
                            v-else
                        >
                            <span class="symbol-label font-size-h3 font-weight-boldest">{{
                                user.firstName.charAt(0) + user.lastName.charAt(0)
                            }}</span>
                        </div>
                        <i
                            class="symbol-badge"
                            :class="[
                                user.status === 'active' ? 'bg-success active' : '',
                                user.status === 'vacation' ? 'bg-danger vacation' : '',
                                user.status === 'busy' ? 'bg-warning busy' : '',
                            ]"
                        ></i>
                    </div>
                    <div>
                        <a
                            href="#"
                            class="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary"
                            >{{ user.firstName }} {{ user.lastName }}</a
                        >
                        <div class="text-muted">{{ user.jobTitle }}</div>
                    </div>
                </div>
                <!--end::User-->
                <!--begin::Contact-->
                <div class="py-9">
                    <div class="d-flex align-items-center justify-content-between mb-2">
                        <span class="font-weight-bold mr-2">Adresa email:</span>
                        <a href="#" class="text-muted text-hover-primary">{{
                            user.email
                        }}</a>
                    </div>
                    <div class="d-flex align-items-center justify-content-between mb-2">
                        <span class="font-weight-bold mr-2">Numar telefon:</span>
                        <span class="text-muted">{{ user.phone }}</span>
                    </div>
                </div>
                <!--end::Contact-->

                <!--begin::Status-->
                <div class="row">
                    <div class="navi-link col-12">
                        <div class="font-weight-normal col-12">
                            Modifica status utilizator
                        </div>
                        <div class="change-status-wrapper">
                            <v-select
                                v-model="selected"
                                :items="options"
                                @change="changeUserStatus()"
                                menu-props="auto"
                                dense
                                outlined
                            >
                                <template v-slot:item="{ status, item, attrs, on }">
                                    <v-list-item
                                        v-on="on"
                                        v-bind="attrs"
                                        default="{ status }"
                                    >
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <v-row no-gutters align="center">
                                                    <span>{{ item.text }}</span>
                                                    <v-spacer></v-spacer>
                                                    <v-chip
                                                        text-color="white"
                                                        :color="item.color"
                                                        small
                                                    ></v-chip>
                                                </v-row>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item> </template
                            ></v-select>
                        </div>
                    </div>
                </div>
                <!--end::Status-->
                <!--begin::Nav-->
                <div class="navi navi-bold navi-hover navi-active navi-link-rounded">
                    <div class="navi-item mb-2">
                        <router-link
                            :to="{ name: 'EditUserInfo', params: { userId: user.id } }"
                            active-class="active"
                            class="navi-link py-4"
                        >
                            <span class="navi-icon mr-2">
                                <span class="svg-icon">
                                    <inline-svg
                                        src="/media/svg/icons/General/User.svg"
                                    ></inline-svg>
                                </span>
                            </span>
                            <span class="navi-text font-size-lg"
                                >Personal Information</span
                            >
                        </router-link>
                    </div>
                    <div class="navi-item mb-2">
                        <router-link
                            :to="{
                                name: 'EditUserDepartaments',
                                params: { userId: user.id },
                            }"
                            active-class="active"
                            class="navi-link py-4"
                        >
                            <span class="navi-icon mr-2">
                                <span class="svg-icon">
                                    <inline-svg
                                        src="/media/svg/icons/Communication/Mail-opened.svg"
                                    ></inline-svg>
                                </span>
                            </span>
                            <span class="navi-text font-size-lg">Departamente</span>
                        </router-link>
                    </div>
                    <div class="navi-item mb-2">
                        <router-link
                            :to="{
                                name: 'EditUserPassword',
                                params: { userId: user.id },
                            }"
                            active-class="active"
                            class="navi-link py-4"
                        >
                            <span class="navi-icon mr-2">
                                <span class="svg-icon">
                                    <inline-svg
                                        src="/media/svg/icons/Communication/Shield-user.svg"
                                    ></inline-svg>
                                </span>
                            </span>
                            <span class="navi-text font-size-lg">Change Password</span>
                        </router-link>
                    </div>
                </div>
                <!--end::Nav-->
            </div>
            <!--end::Body-->
        </div>
        <!--end::Profile Card-->
    </div>
    <!--end::Aside-->
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "UsersEditProfileCard",
    props: ["user"],
    components: {},
    data() {
        return {
            selected: this.user.status,
            options: [
                { value: null, text: "Selecteaza status", disabled: true },
                { value: "active", text: "Disponibil", color: "#1BC5BD" },
                { value: "busy", text: "Ocupat", color: "#F3C200" },
                { value: "vacation", text: "Concediu", color: "#F64E60" },
            ],
        };
    },
    mounted() {},
    computed: {},
    methods: {
        ...mapActions("users", ["getUsersData"]),
        changeUserStatus() {
            let status = this.selected;
            const userId = this.user.id;
            this.$store
                .dispatch("users/sendUserStatus", {
                    userId,
                    status,
                })
                .then((response) => {
                    this.getUsersData(this.$route.params.userId).then(() => {
                        this.selected = this.user.status;
                    });
                });
        },
    },
    created() {
        this.getUsersData(this.$route.params.userId).then(() => {
            this.selected = this.user.status;
        });
    },
};
</script>
