<template>
    <!-- begin:Content -->
    <div class="content d-flex flex-column flex-column-fluid pt-0" id="kt_content">
        <!-- <Subheader /> -->
        <!--begin::Entry-->
        <div class="d-flex flex-column-fluid">
            <!--begin::Container-->
            <div class="container container-xl">
                <!--begin::Profile Personal Information-->
                <div class="d-flex flex-row">
                    <UsersEditProfileCard :user="currentUser" v-if="currentUser" />
                    <!--begin::Content-->
                    <div class="flex-row-fluid ml-lg-8">
                        <router-view :currentUser="currentUser" v-if="currentUser" />
                    </div>
                    <!--end::Content-->
                </div>
                <!--end::Profile Personal Information-->
            </div>
            <!--end::Container-->
        </div>
        <!--end::Entry-->
    </div>
    <!-- end:Content -->
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapActions } from "vuex";
import UsersEditProfileCard from "./UsersEditProfileCard.vue";
// import UsersEditPersonalInfoCard from "./UsersEditPersonalInfoCard.vue";

export default {
    name: "Users",
    props: ["userId"],
    components: {
        UsersEditProfileCard,
        // UsersEditPersonalInfoCard,
    },
    data() {
        return {};
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {
                title: "Lista utilizatori",
                pathName: "EditUser",
                pathParams: { userId: this.user.id },
            },
            {
                title: "Editare utilizator",
            },
        ]);
    },
    computed: {
        ...mapGetters(["user"]),
        // ...mapGetters(["users", "currentUser"]),
        currentUser() {
            return this.$store.getters["users/currentUser"];
        },
    },
    methods: {
        ...mapActions("users", ["getUsersData"]),
    },
    created() {
        this.getUsersData(this.$route.params.userId);
    },
};
</script>

<style scoped>
.container {
    max-width: 1185px !important;
}
</style>
